import React, { useContext, useState } from "react";
import CreditCardInput from "react-credit-card-input";
import ModalFooter from "../ModalFooter";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalCss from "../Modal.module.css";
import OrderCss from "../OrderModal/OrderModal.module.css";
import paymentCss from "./PaymentModal.module.css";
import secureImg from "../../../../assets/img/secure-payment.png";
import backArrow from "../../../../assets/img/back-arrow.png";
import OrderTicketRow from "../OrderModal/OrderTicketRow";

const PaymentModal = () => {
  const { currentStep, setStep, totalOrder } = useContext(ModalStepContext);
  console.log(currentStep, "currentStep");

  const handleBack = () => {
    setStep(currentStep - 1);
    console.log("step", currentStep);
  };

  const getSum = (order) => {
    const res = order
      .map((item) => {
        return item.count * item.price;
      })
      .reduce((a, b) => a + b, 0);
    return res;
  };

  const [showBrief, setShowBrief] = useState(false);

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody}`}>
            <header className={`${ModalCss.modalHeader}`}>
              <a
                onClick={() => handleBack()}
                className={`${ModalCss.backArrow}`}
                style={{ cursor: "pointer" }}
              >
                <img src={backArrow} alt="" />
              </a>
              <h2 className={`${ModalCss.headerTitle}`}>
                Nowruz Party at One Club, Melantrichova 5, Prague 1
              </h2>
              <h2 className={`${ModalCss.headerTime}`}>
                Fri 18 Mar. 2022, 22:00 - 5:00
              </h2>
              <ul className={`${ModalCss.progress}`}>
                {/* <li>
                  <span className={`${ModalCss.mobileHide}`}>
                    Select tickets
                  </span> 
                  <span className={`${ModalCss.mobileOnly}`}>Tickets</span>
                </li>*/}
                <li>Your details</li>
                <li className={`${ModalCss.active}`}>Payment</li>
              </ul>
            </header>

            {showBrief && (
              <table className={`${OrderCss.summary}`}>
                <thead>
                  <tr className={`${OrderCss.eventName}`}>
                    <th colSpan={4}>
                      <span className={`${OrderCss.name}`}>
                        Nowruz Party at One Club, Melantrichova 5, Prague 1
                      </span>
                      <span className={`${OrderCss.date}`}>
                        Fri 18 Mar. 2022, 22:00 - 5:00
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {totalOrder.map((item) => (
                    <OrderTicketRow
                      name={item.name}
                      price={item.price}
                      quantity={item.count}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr className={`${OrderCss.total}`}>
                    <td colSpan={3}>Order total</td>
                    <td className="text-end">{getSum(totalOrder)},- CZK</td>
                  </tr>
                </tfoot>
              </table>
            )}

            <h3>
              {" "}
              Order total: <var>Kč {getSum(totalOrder)}</var>
              <span
                onClick={() => {
                  setShowBrief(!showBrief);
                }}
                className={`${paymentCss.basketIcon}`}
              >
                {showBrief ? "Hide" : "Show"} summary
              </span>
            </h3>
            <p>
              You are ordering your selected tickets from previous steps (
              <a className="text-decoration-underline" href="">
                view privacy policy in new tab
              </a>
              )
            </p>
            <p>Tickets will be sent to you via email</p>
            <div className={paymentCss.paymentMethods}>
              <div className={paymentCss.paymentMethod}>
                <div className={paymentCss.cardHolder}>
                  <div className={paymentCss.payByCard}>
                    <form action="" className={paymentCss.paymentForm}>
                      <div className={paymentCss.formRow}>
                        <div className={paymentCss.cardElementData}>
                          <label htmlFor="cardHolder-name">Name on card</label>
                          <input type="text" id="cardHolder-name" />
                        </div>
                        <div className={paymentCss.cardElementData}>
                          <label htmlFor="cardHolder-name">
                            Postcode / ZIP
                          </label>
                          <input type="text" id="cardHolder-name" />
                        </div>
                        {/* <div className={paymentCss.cardElement}>
                          <div className={paymentCss.cardNumElement}>
                            <input className={paymentCss.cardNumInput} />
                          </div>
                        </div> */}
                        <CreditCardInput
                          fieldClassName="input"
                          containerStyle={{
                            border: "1px solid #c9c9c9",
                            borderRadius: "5px",
                            width: "100%",
                            height: "40px",
                          }}
                          // fieldStyle={{ height: "40px" }}
                        />
                        <div className={paymentCss.elementsError}></div>
                      </div>
                      <div className={paymentCss.formRow}>
                        <input
                          type="submit"
                          value={`Pay Kč ${getSum(totalOrder)}`}
                          className={paymentCss.paymentButton}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <img src={secureImg} className={paymentCss.sslLogo} alt="" />
              </div>
            </div>
          </div>
        </div>

        <ModalFooter leftItems={true} />
      </div>
    </div>
  );
};

export default PaymentModal;
