import React, { useContext, useState } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import AboutUsCss from "./AboutUsModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import { ArrowDown } from "react-feather";

const AboutUsModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  const handlePreContactModalShow = () => {
    setStep(5);
  };

  const [itemsShow, setItemsShow] = useState({
    privacyPolicy: false,
    termsOfService: false,
  });

  const togglePrivacyPolicy = () => {
    setItemsShow({
      termsOfService: false,
      privacyPolicy: !itemsShow.privacyPolicy,
      contactUs: false,
    });
  };
  const toggleTermsOFService = () => {
    setItemsShow({
      privacyPolicy: false,
      termsOfService: !itemsShow.termsOfService,
      contactUs: false,
    });
  };

  const toggleContactUs = () => {
    setItemsShow({
      privacyPolicy: false,
      termsOfService: false,
      contactUs: !itemsShow.contactUs,
    });
  };

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${AboutUsCss.privacyHeader}`}></h2>
            <div className={`mt-5 mb-3 ${AboutUsCss.privacyTextHolder}`}>
              <p className={` mt-5 mb-3  fs-2  ${AboutUsCss.privacy}`}>
                <span className="fw-bold">DIACCO INVEST </span>
                <span>s.r.o</span>
              </p>

              <p className={AboutUsCss.privacyText}>
                VAT number: <span>CZ08697663</span>
                <br />
                Paťanka 2722/11b, Dejvice, 160 00 Prague 6, Czechia
                <br />
                Maintained by the Municipal Court in Prague, Section C, File No.
                323527
              </p>

              <p className={AboutUsCss.toggle}>
                <span onClick={toggleTermsOFService}>Terms Of Services</span>
                <ArrowDown />
              </p>

              {itemsShow.termsOfService && (
                <>
                  <div className={AboutUsCss.holder}>
                    <h2 className={AboutUsCss.heading}>
                      GENERAL AND BUSINESS CONDITIONS AND COMPLAINTS PROCEDURE
                    </h2>
                    <p>
                      GENERAL AND BUSINESS TERMS AND CONDITIONS AND COMPLAINTS
                      RULES GOVERNING THE SALE AND PURCHASE OF TICKETS, THROUGH
                      DIACCO WEBSITES (hereinafter referred to as the
                      "Conditions")
                    </p>
                    <h3 className={AboutUsCss.secondaryHeading}>
                      A. SUBJECT MATTER OF THE TERMS AND CONDITIONS
                    </h3>
                    <ul className={AboutUsCss.contentUl}>
                      <li>
                        <p>
                          1. These Conditions of the DIACCO INVEST s.r.o., IČO:
                          08697663, DIČ: CZ08697663, Paťanka 2722/11b, Dejvice,
                          160 00 Prague 6, Czechia, maintained by the Municipal
                          Court in Prague, Section C, File No. 323527,
                          (hereinafter referred to as the “DIACCO”) regulate the
                          rights and obligations between the DIACCO on the one
                          hand and Customers on the other arising from - sales
                          and purchases of Tickets for cultural or other similar
                          Events listed in the DIACCO's program, through the
                          DIACCO's Website.
                        </p>
                      </li>
                      <li>
                        <p>
                          2. All contractual relations between the DIACCO and
                          the Customers are governed by the legal order of the
                          Czech Republic, in particular Act No. 89/2012 Coll.,
                          The Civil Code, as amended (hereinafter referred to as
                          the “NOZ”).
                        </p>
                      </li>
                      <li>
                        <p>
                          3. The conditions are accessible to everyone on the
                          DIACCO's Website.
                        </p>
                      </li>
                    </ul>
                    <h3 className={AboutUsCss.secondaryHeading}>
                      B. DEFINITIONS
                    </h3>
                    <p>
                      For the purposes of these Terms and Conditions and the
                      relationship between the DIACCO and the Customers, they
                      have the terms set out below following meaning:
                      <br />
                      <br />
                      “Events": Entertainment
                      <br />
                      "Pre-sale": Ticket with a 50% discount, which will sell 2
                      weeks before the event until it is sold out
                      <br />
                      “First wave”: Ticket with a 20% discount, which will sell
                      after "Pre-sale" is sold out.
                      <br />
                      “Second wave”: Ticket with a 10% discount, which will sell
                      after “First wave” is sold out.
                    </p>
                    <h3 className={AboutUsCss.secondaryHeading}>
                      Direct purchase of E-Tickets via online payment
                    </h3>
                    <p>
                      The Customer may purchase E-Tickets directly through
                      online payment in accordance with the procedure set forth
                      on the Website. Payment of E- Tickets is made through the
                      payment gateway by the Customer's card.
                    </p>
                    <h3 className={AboutUsCss.secondaryHeading}>
                      Cancellation of the Event, program changes, refund of
                      admission
                    </h3>
                    <ul className={AboutUsCss.contentUl}>
                      <li>
                        <p>
                          1. DIACCO or other The organizer reserves the right to
                          change the program, performers and dates.
                        </p>
                      </li>
                      <li>
                        <p>
                          2. Upon cancellation or program change of the Event,
                          the Customer is entitled to exchange Tickets or refund
                          of the entrance fee as soon as possible. In these
                          cases, the instructions of the business department of
                          the DIACCO follow. In the case of payment of the
                          entrance fee via online payment, the DIACCO ensures
                          the return of the entrance fee in a non-cash form.
                        </p>
                      </li>
                      <li>
                        <p>
                          3. In the case of cancellation of the Event, Customers
                          will be informed of the method of refunding the ticket
                          price electronically by the email.
                        </p>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <p className={AboutUsCss.toggle}>
                <span onClick={togglePrivacyPolicy}>Privacy Policy</span>
                <ArrowDown />
              </p>

              {itemsShow.privacyPolicy && (
                <>
                  <p className={AboutUsCss.privacyText}>
                    DIACCO INVEST s.r.o., based Paťanka 2722/11b, Dejvice, 160
                    00 Prague 6, Czechia, registration number 08697663,
                    Maintained by the Municipal Court in Prague, Section C, File
                    No. 323527 (the “Operator”), undertakes to protect and
                    respect your privacy. This Personal Data Protection Policy
                    sets out the basic principles used to process the personal
                    data that we collect from you or that you provide to us.
                    Please read the following information carefully in order to
                    understand how we view personal data and how we treat such
                    data.
                  </p>
                  <br />
                  <h3 className="p-0">Right to information</h3>
                  <p className={`${AboutUsCss.privacyText}`}>
                    You have the right to request the Operator for information
                    about the personal data we process about you, to what extent
                    it is processed, and for what purpose. We will provide you
                    with this information free of charge within 30 days. If you
                    request us to disclose the information we have registered
                    about you, we will first need to verify that you are
                    genuinely the person to whom this information belongs.
                  </p>
                  <br />
                  <h3 className="p-0">Who you can contact</h3>
                  <p className={`${AboutUsCss.privacyText}`}>
                    If you have any questions regarding personal data
                    protection, you may also contact our registered office at:
                    DIACCO INVEST s.r.o., based Paťanka 2722/11b, Dejvice, 160
                    00 Prague
                  </p>
                </>
              )}
              <p className={AboutUsCss.toggle}>
                <span onClick={toggleContactUs}>Contact Us</span>
                <ArrowDown />
              </p>

              {itemsShow.contactUs && (
                <>
                  <p className="mt-3">Please complete the form below</p>
                  <div className={`${AboutUsCss.checkoutForm}`}>
                    <form action="">
                      <dl className={`${AboutUsCss.zendForm}`}>
                        <Input
                          id="name"
                          type="text"
                          name="Your name"
                          element="name-element"
                          errorMsg=""
                          required={true}
                        />
                        <Input
                          id="email"
                          type="email"
                          name="Your email"
                          element="email-element"
                          required={true}
                        />
                        <TextereaInput
                          id="message"
                          name="Message"
                          element="message-element"
                          required={false}
                        />
                        <SelectInput
                          id="event"
                          name="Please select the event you purchased for"
                          element="event-element"
                          required={false}
                          value={[
                            "Please select",
                            "Fri 18 March 2022 10:00 PM - 5:00 AM: Nowruz Party",
                          ]}
                        />

                        <div className={`${AboutUsCss.inputHolder}`}>
                          <dd
                            id="submit-element"
                            className={`${AboutUsCss.submitElement}`}
                          >
                            <input
                              className={`${AboutUsCss.submitItem}`}
                              type="submit"
                              id="submit"
                              name="submit"
                              value="Send message"
                            />
                          </dd>
                        </div>
                      </dl>
                    </form>
                    <br className={`${AboutUsCss.spr}`} />
                  </div>
                </>
              )}
              {/* <h3 className="p-0 text-center"></h3>
              <hr className={AboutUsCss.line} />
              <p
                onClick={handlePreContactModalShow}
                className={AboutUsCss.link}
              >
                Click here to contact us (DIACCO)
              </p> */}
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default AboutUsModal;
