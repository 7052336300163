import React from "react";
import { useHistory } from "react-router-dom";
import DiaccoEventCss from "./DiaccoEvent.module.css";

const DiaccoEventRow = ({
  id,
  name,
  pic,
  time,
  date,
  location,
  button,
  month,
  day,
}) => {
  let history = useHistory();

  const handleClick = (id) => {
    if (name === "Nowruz Party") history.push(`/events/${id}`);
  };
  return (
    <div className={`row ${DiaccoEventCss.eventRow}`}>
      <a className="d-flex flex-wrap justify-content-lg-end">
        <div className="col-lg-8 col-md-9 col-sm-8 col-12">
          <div className="d-flex row">
            {/* <div className={`col-2 d-flex me-auto ${DiaccoEventCss.imgBox}`}>
              <img src={pic} alt="" className={`${DiaccoEventCss.eventImg}`} />
            </div> */}
            <div className={`col-2 me-auto ${DiaccoEventCss.dateBox}`}>
              <p>{month}</p>
              <p>{day}</p>
            </div>

            <div className={`col-6 col-md-9 ${DiaccoEventCss.eventDetails}`}>
              <h2 className={`${DiaccoEventCss.eventName}`}>{name}</h2>
              <h3 className={`${DiaccoEventCss.eventTime}`}>
                <i className="fa fa-calendar me-2"></i>
                {date}, {time}
              </h3>
              <h3 className={`${DiaccoEventCss.eventLocation}`}>
                <i className="fa fa-map-marker me-2"></i>
                {location}
              </h3>
            </div>
          </div>
        </div>

        <div
          className={`col-lg-4 col-md-3 col-sm-4 col-12  d-flex ${DiaccoEventCss.btnBox}`}
        >
          <span
            onClick={() => handleClick(id)}
            role="button"
            className={`btn text-capitalize align-self-center ms-auto ${DiaccoEventCss.eventBtn}`}
          >
            {button}
          </span>
        </div>
      </a>
    </div>
  );
};

export default DiaccoEventRow;
