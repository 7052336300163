import React from 'react';
import { Field, ErrorMessage } from "formik";
import InputCss from './Input.module.css';

const Input = ({ id, name, type, value, element, errorMsg, required }) => {

    return (
        <div className={`${InputCss.inputHolder}`}>
            <dt id={element}>
                <label className={required && InputCss.required} htmlFor="name">{name}</label>
            </dt>
            <dd id={element}>
                <input
                    className={`${InputCss.inputItem}`}
                    type={type}
                    id={id}
                    name={id}
                    value={value}
                />
                <div name={id}
                    component="div" className={`${InputCss.inputError}`}> {errorMsg} </div>
            </dd>
        </div>
    );
}

export default Input;