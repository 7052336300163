import React from "react";
import { Field, ErrorMessage } from "formik";
import EventTicketRowCss from "./EventTicketRow.module.css";

const EventTicketRow = ({ name, price, ticketNum }) => {
  return (
    <div className={`${EventTicketRowCss.ticketRow}`}>
      <span className={`${EventTicketRowCss.ticketName}`}>
        <label htmlFor="">
          <var className={`${EventTicketRowCss.ticketVar}`}>{name}</var>
          <div className={`${EventTicketRowCss.ticketPrice}`}></div>
        </label>
      </span>

      <span className={`${EventTicketRowCss.ticketPrice}`}>{price},- CZK </span>

      <span className={`${EventTicketRowCss.ticketQuantity}`}>
        <Field
          as="select"
          name={ticketNum}
          className={`${EventTicketRowCss.ticketSelect}`}
        >
          <option value="0" selected="selected">
            0
          </option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Field>
      </span>
      <br className={`${EventTicketRowCss.space}`} />
      <hr className={` ${EventTicketRowCss.EventsBorder}`} />
    </div>
  );
};

export default EventTicketRow;
