const fakeTickets = [
  {
    name: "Pre sales",
    price: 150,
    ticket: "ticketNum1",
  },
  {
    name: "First wave",
    price: 240,
    ticket: "ticketNum2",
  },
  {
    name: "Second wave",
    price: 270,
    ticket: "ticketNum3",
  },
  {
    name: "VIP",
    price: 900,
    ticket: "ticketNum4",
  },
];

export default fakeTickets;
