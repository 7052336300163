import React, { useContext } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import privacyCss from "./PrivacyPolicyModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";

const PrivacyPolicyModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);

  const handleContactModalShow = () => {
    setStep(currentStep + 1);
  };

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${privacyCss.privacyHeader}`}></h2>
            <div className={`mt-5 mb-3 ${privacyCss.privacyTextHolder}`}>
              <h2
                className={`text-center mt-5 mb-3 fw-bold fs-2 text-capitalize ${privacyCss.privacy}`}
              >
                Personal Data Protection Policy
              </h2>
              <p className={privacyCss.privacyText}>
                DIACCO INVEST s.r.o., based Paťanka 2722/11b, Dejvice, 160 00
                Prague 6, Czechia, registration number 08697663, Maintained by
                the Municipal Court in Prague, Section C, File No. 323527 (the
                “Operator”), undertakes to protect and respect your privacy.
                This Personal Data Protection Policy sets out the basic
                principles used to process the personal data that we collect
                from you or that you provide to us. Please read the following
                information carefully in order to understand how we view
                personal data and how we treat such data.
              </p>
              <br />
              <h3 className="p-0">Right to information</h3>
              <p className={`${privacyCss.privacyText}`}>
                You have the right to request the Operator for information about
                the personal data we process about you, to what extent it is
                processed, and for what purpose. We will provide you with this
                information free of charge within 30 days. If you request us to
                disclose the information we have registered about you, we will
                first need to verify that you are genuinely the person to whom
                this information belongs.
              </p>
              <br />
              <h3 className="p-0">Who you can contact</h3>
              <p className={`${privacyCss.privacyText}`}>
                If you have any questions regarding personal data protection,
                you may also contact our registered office at: DIACCO INVEST
                s.r.o., based Paťanka 2722/11b, Dejvice, 160 00 Prague
              </p>
            </div>
            <hr className={privacyCss.line} />
            <p>
              <a
                onClick={() => handleContactModalShow()}
                className={`text-decoration-underline ${privacyCss.cursor}`}
              >
                Click here to contact us (DIACCO) for details of our privacy
                policy.
              </a>
            </p>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
