import React, { useState } from "react";
import "../assets/style/main.css";
import { Route, Switch } from "react-router-dom";
import Landing from "../component/Landing/Landing";
import DiaccoInvest from "./../component/DiaccoInvest/DiaccoInvest";
import DiaccoFleet from "./../component/DiaccoFleet/DiaccoFleet";
import DiaccoStudy from "./../component/DiaccoStudy/DiaccoStudy";
import DiaccoEvent from "./../component/DiaccoEvent/DiaccoEvent";
import DiaccoEventDetail from "../component/DiaccoEventDetail/DiaccoEventDetail";
import DiaccoEventDetail2 from "../component/DiaccoEventDetail/DiaccoEventDetail2";
import DiaccoEventDetail3 from "../component/DiaccoEventDetail/DiaccoEventDetail3";

import ModalStepContext from "../core/context/ModalStepContext";
import ModalShowContext from "../core/context/ModalShowContext";
import PrivacyPolicy from "../component/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "../component/ContactUs/ContactUs";

function App() {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [totalOrder, setTotalOrder] = useState([]);

  return (
    <div className="App">
      <ModalShowContext.Provider value={{ show: show, setShow }}>
        <ModalStepContext.Provider
          value={{ currentStep: step, setStep, totalOrder, setTotalOrder }}
        >
          <Switch>
            <Route path="/events/1" component={DiaccoEventDetail} />
            <Route path="/events/2" component={DiaccoEventDetail2} />
            <Route path="/events/3" component={DiaccoEventDetail3} />
            <Route path="/events" component={DiaccoEvent} />
            <Route path="/invest" component={DiaccoInvest} />
            <Route path="/fleet" component={DiaccoFleet} />
            <Route path="/study" component={DiaccoStudy} />
            {/* <Route path="/privacy-policy" component={PrivacyPolicy} /> */}
            <Route path="/contact_us" exact component={ContactUs} />
            <Route path="/" exact component={Landing} />
            <Route path="/" exact component={ContactUs} />
          </Switch>
        </ModalStepContext.Provider>
      </ModalShowContext.Provider>
    </div>
  );
}

export default App;
