import React, { useContext, useState } from "react";
import ModalCss from "../Modal.module.css";
import TicketRow from "./TicketRow/TicketRow";
import fakeTickets from "../../../../core/fakeTickets";
import ModalFooter from "../ModalFooter";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import { Formik, Form } from "formik";

const TicketModal = () => {
  const { currentStep, setStep, totalOrder, setTotalOrder } =
    useContext(ModalStepContext);
  console.log(currentStep, "currentStep");

  const handleTicketClick = () => {
    setStep(currentStep + 1);
    console.log("step", currentStep);
  };

  const [errorShow, setErrorShow] = useState(false);

  const showHide = errorShow ? "d-block" : "d-none";

  const getOrder = (values) => {
    let structuredOrder = { ...values };
    for (var key in structuredOrder) {
      if (structuredOrder.hasOwnProperty(key)) {
        structuredOrder[key] = {
          count: +structuredOrder[key],
          price: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.price)[0],
          name: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.name)[0],
        };
      }
    }

    const tempArrayOrder = [];
    for (var o in structuredOrder) {
      tempArrayOrder.push(structuredOrder[o]);
    }
    const order = tempArrayOrder.filter((i) => i.price);
    setTotalOrder(order);
    return order;
  };

  const getSum = (values) => {
    let structuredOrder = { ...values };
    for (var key in structuredOrder) {
      if (structuredOrder.hasOwnProperty(key)) {
        structuredOrder[key] = {
          count: +structuredOrder[key],
          price: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.price)[0],
          name: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.name)[0],
        };
      }
    }

    const tempArrayOrder = [];
    for (var o in structuredOrder) {
      tempArrayOrder.push(structuredOrder[o]);
    }
    const order = tempArrayOrder.filter((i) => i.price);
    const res = order
      .map((item) => {
        return item.count * item.price;
      })
      .reduce((a, b) => a + b, 0);
    return res;
  };

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody}`}>
            <header className={`${ModalCss.modalHeader}`}>
              <h2 className={`${ModalCss.headerTitle}`}>
                Nowruz Party at One Club, Melantrichova 5, Prague 1
              </h2>
              <h2 className={`${ModalCss.headerTime}`}>
                Fri 18 Mar. 2022, 22:00 - 5:00
              </h2>
              <ul className={`${ModalCss.progress}`}>
                {/* <li className={`${ModalCss.active}`}>
                  <span className={`${ModalCss.mobileHide}`}>
                    Select tickets
                  </span>
                  <span className={`${ModalCss.mobileOnly}`}>Tickets</span>
                </li> */}
                <li>Your details</li>
                <li>Payment</li>
              </ul>
            </header>

            <div className={`${ModalCss.ticketForm}`}>
              <Formik
                initialValues={{
                  ticketNum: "",
                }}
                onSubmit={(values) => {
                  getOrder(values);

                  {
                    Object.keys(values).length > 1 && handleTicketClick();
                  }
                  {
                    Object.keys(values).length > 1
                      ? setErrorShow(false)
                      : setErrorShow(true);
                  }
                }}
              >
                {({ values }) => (
                  <div>
                    {/* alert if no ticket selected */}
                    <div
                      className={`error ${showHide} ${ModalCss.errorAlertBox}`}
                    >
                      <p className={`${ModalCss.alertMsg}`}>
                        <strong>Please note the following errors:</strong>
                        <br />
                        You need to select at least one ticket to make an order.
                      </p>
                    </div>
                    {/* end of alert if no ticket selected */}
                    <Form>
                      <div className={`${ModalCss.formItems}`}>
                        {fakeTickets.map((ticket) => (
                          <TicketRow
                            name={ticket.name}
                            price={ticket.price}
                            ticketNum={ticket.ticket}
                          />
                        ))}
                        <div className={`${ModalCss.submitBox}`}>
                          <div className={`${ModalCss.subtotalBox}`}>
                            <span className={`${ModalCss.subtotalLabel}`}>
                              Subtotal
                            </span>
                            <span className={`${ModalCss.subtotal}`}>
                              {getSum(values)},- CZK
                            </span>
                          </div>
                          <span className={`${ModalCss.submitBox2}`}>
                            <input
                              role="button"
                              className={`${ModalCss.submitButton}`}
                              type="submit"
                              name="submit"
                              value="Next"
                            />
                          </span>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <ModalFooter leftItems={true} />
      </div>
    </div>
  );
};

export default TicketModal;
