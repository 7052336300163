import React, { useContext, useEffect } from "react";
import DiaccoEventCss from "./DiaccoEvent.module.css";
import DetailCss from "../DiaccoEventDetail/DiaccoEventDetail.module.css";
import DiaccoEventRow from "./DiaccoEventRow";
import fakeEvents from "../../core/fakeEvents";
import Footer from "../Footer/Footer";

import PrivacyPolicyModal from "../common/Modal/PrivacyPolicyModal/PrivacyPolicyModal";
import PreContactModal from "../common/Modal/PreContactModal/PreContactModal";
import ContactModal from "../common/Modal/ContactModal/ContactModal";
import ResendTicketModal from "../common/Modal/ResendTicketModal/ResendTicketModal";
import EmailModal from "../common/Modal/EmailModal/EmailModal";

import ModalStepContext from "../../core/context/ModalStepContext";
import ModalShowContext from "../../core/context/ModalShowContext";
import AboutUsModal from "../common/Modal/AboutUsModal/AboutUsModal";
import SheetLogo from "../../assets/img/information icon.png";
import { Home } from "react-feather";

const DiaccoEvent = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  const { show, setShow } = useContext(ModalShowContext);

  const showHide = show ? "d-block" : "d-none";

  const showModal = () => {
    setShow(!show);
    setStep(1);
  };

  useEffect(() => {
    setStep(1);
    setShow(false);
  }, []);

  return (
    <div className={`${DiaccoEventCss.eventPageBg}`}>
      <div className={`${showHide} ${DetailCss.overlayWrapper}`}>
        <div className={`${DetailCss.overlay}`}></div>
        <a
          onClick={() => showModal()}
          className={`${DetailCss.closeButton}`}
          role="button"
        >
          <svg
            className={`${DetailCss.closeIcon}`}
            xmlns="http://www.w3.org/2000/svg"
            width="57.276"
            height="57.276"
            viewBox="0 0 57.276 57.276"
          >
            <g
              id="Group_1"
              data-name="Group 1"
              transform="translate(-593.801 -249.423)"
            >
              <line
                id="Line_1"
                data-name="Line 1"
                y2="70"
                transform="translate(647.188 253.312) rotate(45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
              <line
                id="Line_2"
                data-name="Line 2"
                y2="70"
                transform="translate(597.69 253.312) rotate(-45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
            </g>
          </svg>
        </a>
        <div className={`${DetailCss.overlayWindow}`}>
          <div className={`${DetailCss.setBg}`}>
            {currentStep === 4 && <PrivacyPolicyModal />}
            {currentStep === 5 && <ContactModal />}
            {currentStep === 6 && <PreContactModal />}
            {currentStep === 7 && <ResendTicketModal />}
            {currentStep === 8 && <EmailModal />}
            {currentStep === 9 && <AboutUsModal />}
          </div>
        </div>
      </div>

      <div className={` ${DiaccoEventCss.eventBox}`}>
        <header>
          <div className={`${DiaccoEventCss.container}`}>
            <div className={`${DiaccoEventCss.eventHeader}`}>
              <span
                className={DiaccoEventCss.home}
                onClick={() => {
                  setShow(true);
                  setStep(9);
                }}
              >
                <img src={SheetLogo} alt="logo" />
              </span>
            </div>
          </div>
        </header>
        <div
          className={`${DiaccoEventCss.container} ${DiaccoEventCss.eventList} mb-5 `}
        >
          <div className={`${DiaccoEventCss.container}`}>
            {fakeEvents.map((event) => (
              <div>
                <DiaccoEventRow
                  id={event._id}
                  name={event.name}
                  pic={event.pic}
                  time={event.time}
                  date={event.date}
                  month={event.month}
                  day={event.day}
                  location={event.location}
                  button={event.button}
                />
                <hr className={`mt-4 ${DiaccoEventCss.EventsBorder}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default DiaccoEvent;
