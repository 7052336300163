import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputCss from "./Input.module.css";

const Input = ({
  name,
  inputLabel,
  type,
  value,
  element,
  errorMsg,
  required,
}) => {
  return (
    <div className={`${InputCss.inputHolder}`}>
      <dt id={element}>
        <label className={required && InputCss.required} htmlFor={name}>
          {inputLabel}
        </label>
      </dt>
      <dd id={element}>
        <Field
          className={`${InputCss.inputItem}`}
          type={type}
          id={name}
          name={name}
          as=""
          placeholder=""
        />
        <ErrorMessage
          name={name}
          component="div"
          className={`${InputCss.inputError} text-danger`}
        />
      </dd>
    </div>
  );
};

export default Input;
