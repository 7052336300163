import React from 'react';
import InputCss from './Input.module.css';

const TextereaInput = ({ id, name, type, value, element, errorMsg, required }) => {
    // const showHide = required ? InputCss.required : InputCss.nrequired;
    return (
        <div className={`${InputCss.inputHolder}`}>
            <dt id={element}>
                <label className={required && InputCss.required}  htmlFor="name">{name}</label>
            </dt>
            <dd id={element}>
                {/* <input
                    className={`${InputCss.inputItem}`}
                    type={type}
                    id={id}
                    name={id}
                    value={value}
                /> */}
                <textarea name={id} id={id} cols="60" rows="6"></textarea>
                <div className={`${InputCss.inputError}`}> {errorMsg} </div>
            </dd>
        </div>
    );
}

export default TextereaInput;