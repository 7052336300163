import React, { useContext } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import TermsOfServiceCss from "./TermsOfServicesModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import backArrow from "../../../../assets/img/back-arrow.png";

const TermsOfServicesModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  const handleBack = () => {
    setStep(2);
  };

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${TermsOfServiceCss.privacyHeader}`}>
              <a
                onClick={() => handleBack()}
                className={`${ModalCss.backArrow}`}
                style={{ cursor: "pointer" }}
              >
                <img src={backArrow} alt="" />
              </a>
            </h2>
            <div className={`mt-5 mb-3 ${TermsOfServiceCss.privacyTextHolder}`}>
              <h2
                className={`text-center mt-5 mb-3 fw-bold fs-2 text-capitalize ${TermsOfServiceCss.privacy}`}
              >
                Terms Of Services
              </h2>
              <hr className={TermsOfServiceCss.line} />
              <div className={TermsOfServiceCss.holder}>
                <h2 className={TermsOfServiceCss.heading}>
                  GENERAL AND BUSINESS CONDITIONS AND COMPLAINTS PROCEDURE
                </h2>
                <p>
                  GENERAL AND BUSINESS TERMS AND CONDITIONS AND COMPLAINTS RULES
                  GOVERNING THE SALE AND PURCHASE OF TICKETS, THROUGH DIACCO
                  WEBSITES (hereinafter referred to as the "Conditions")
                </p>
                <h3 className={TermsOfServiceCss.secondaryHeading}>
                  A. SUBJECT MATTER OF THE TERMS AND CONDITIONS
                </h3>
                <ul className={TermsOfServiceCss.contentUl}>
                  <li>
                    <p>
                      1. These Conditions of the DIACCO INVEST s.r.o., IČO:
                      08697663, DIČ: CZ08697663, Paťanka 2722/11b, Dejvice, 160
                      00 Prague 6, Czechia, maintained by the Municipal Court in
                      Prague, Section C, File No. 323527, (hereinafter referred
                      to as the “DIACCO”) regulate the rights and obligations
                      between the DIACCO on the one hand and Customers on the
                      other arising from - sales and purchases of Tickets for
                      cultural or other similar Events listed in the DIACCO's
                      program, through the DIACCO's Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      2. All contractual relations between the DIACCO and the
                      Customers are governed by the legal order of the Czech
                      Republic, in particular Act No. 89/2012 Coll., The Civil
                      Code, as amended (hereinafter referred to as the “NOZ”).
                    </p>
                  </li>
                  <li>
                    <p>
                      3. The conditions are accessible to everyone on the
                      DIACCO's Website.
                    </p>
                  </li>
                </ul>
                <h3 className={TermsOfServiceCss.secondaryHeading}>
                  B. DEFINITIONS
                </h3>
                <p>
                  For the purposes of these Terms and Conditions and the
                  relationship between the DIACCO and the Customers, they have
                  the terms set out below following meaning:
                  <br />
                  <br />
                  “Events": Entertainment
                  <br />
                  "Pre-sale": Ticket with a 50% discount, which will sell 2
                  weeks before the event until it is sold out
                  <br />
                  “First wave”: Ticket with a 20% discount, which will sell
                  after "Pre-sale" is sold out.
                  <br />
                  “Second wave”: Ticket with a 10% discount, which will sell
                  after “First wave” is sold out.
                </p>
                <h3 className={TermsOfServiceCss.secondaryHeading}>
                  Direct purchase of E-Tickets via online payment
                </h3>
                <p>
                  The Customer may purchase E-Tickets directly through online
                  payment in accordance with the procedure set forth on the
                  Website. Payment of E- Tickets is made through the payment
                  gateway by the Customer's card.
                </p>
                <h3 className={TermsOfServiceCss.secondaryHeading}>
                  Cancellation of the Event, program changes, refund of
                  admission
                </h3>
                <ul className={TermsOfServiceCss.contentUl}>
                  <li>
                    <p>
                      1. DIACCO or other The organizer reserves the right to
                      change the program, performers and dates.
                    </p>
                  </li>
                  <li>
                    <p>
                      2. Upon cancellation or program change of the Event, the
                      Customer is entitled to exchange Tickets or refund of the
                      entrance fee as soon as possible. In these cases, the
                      instructions of the business department of the DIACCO
                      follow. In the case of payment of the entrance fee via
                      online payment, the DIACCO ensures the return of the
                      entrance fee in a non-cash form.
                    </p>
                  </li>
                  <li>
                    <p>
                      3. In the case of cancellation of the Event, Customers
                      will be informed of the method of refunding the ticket
                      price electronically by the email.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default TermsOfServicesModal;
