import eventImg1 from "../assets/img/1.png";
import eventImg2 from "../assets/img/2.png";
import eventImg3 from "../assets/img/ev3.jpg";

const fakeEvents = [
  {
    _id: 1,
    name: "Nowruz Party",
    pic: eventImg1,
    time: "22:00 - 5:00",
    date: "Fri 18 Mar. 2022",
    month: "March",
    day: "18",
    location: "One Club, Melantrichova 5, Prague 1",
    button: "Buy Tickets",
  },
  {
    _id: 2,
    name: "Erasmus Cermony",
    pic: eventImg2,
    time: "22:00 - 5:00",
    date: "Sat 2 Jul. 2022",
    month: "July",
    day: "02",
    location: "One Club, Melantrichova 5, Prague 1",
    button: "Buy Tickets",
  },
  {
    _id: 3,
    name: "Erasmus Welcome II.",
    pic: eventImg3,
    time: "22:00 - 5:00",
    date: "Sat 6 Aug 2022",
    month: "August",
    day: "06",
    location: "One Club, Melantrichova 5, Prague 1",
    button: "Buy Tickets",
  },
];

export default fakeEvents;
