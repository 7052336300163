import React from 'react';
import InputCss from './Input.module.css';

const SelectInput = ({ id, name, type, value, element, errorMsg, required }) => {
    return (
        <div className={`${InputCss.inputHolder}`}>
            <dt id={element}>
                <label className={required && InputCss.required} htmlFor="name">{name}</label>
            </dt>
            <dd id={element}>
                <select name={id} id={id}>
                    {value.map(value =>
                        (<option value="">{value}</option>)
                        )}
                </select>
               
                <div className={`${InputCss.inputError}`}> {errorMsg} </div>
            </dd>
        </div>
    );
}

export default SelectInput;