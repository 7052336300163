import React, { useContext, useEffect, useState } from "react";
import TicketModal from "../common/Modal/TicketModal/TicketModal";
import Footer from "../Footer/Footer";
import eventImg from "../../assets/img/1.png";
import DetailCss from "./DiaccoEventDetail.module.css";
import OrderModal from "../common/Modal/OrderModal/OrderModal";
import PaymentModal from "../common/Modal/PaymentModal/PaymentModal";
import PrivacyPolicyModal from "../common/Modal/PrivacyPolicyModal/PrivacyPolicyModal";
import PreContactModal from "../common/Modal/PreContactModal/PreContactModal";
import ContactModal from "../common/Modal/ContactModal/ContactModal";
import ResendTicketModal from "../common/Modal/ResendTicketModal/ResendTicketModal";
import EmailModal from "../common/Modal/EmailModal/EmailModal";
import * as Yup from "yup";

import ModalStepContext from "../../core/context/ModalStepContext";
import ModalShowContext from "../../core/context/ModalShowContext";
import TermsOfServicesModal from "../common/Modal/TermsOfServicesModal/TermsOfServicesModal";
import { Form, Formik } from "formik";
import fakeTickets from "../../core/fakeTickets";
import TicketRow from "../common/Modal/TicketModal/TicketRow/TicketRow";
import EventTicketRow from "./TicketRow/EventTicketRow";

const DiaccoEventDetail = () => {
  const { currentStep, setStep, setTotalOrder } = useContext(ModalStepContext);
  const { show, setShow } = useContext(ModalShowContext);

  // const [show, setShow] = useState(false);
  // const [step, setStep] = useState(1);
  const [errorShow, setErrorShow] = useState(false);

  const showHide = errorShow ? "d-block" : "d-none";
  const modalShowHide = show ? "d-block" : "d-none";
  const showModal = () => {
    setStep(2);
    setShow(!show);
  };

  const handleResendTicketModalShow = () => {
    setShow(!show);
    setStep(7);
  };
  const handleContactModalShow = () => {
    setShow(!show);
    setStep(5);
  };

  const getOrder = (values) => {
    let structuredOrder = { ...values };
    for (var key in structuredOrder) {
      if (structuredOrder.hasOwnProperty(key)) {
        structuredOrder[key] = {
          count: +structuredOrder[key],
          price: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.price)[0],
          name: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.name)[0],
        };
      }
    }

    const tempArrayOrder = [];
    for (var o in structuredOrder) {
      tempArrayOrder.push(structuredOrder[o]);
    }
    const order = tempArrayOrder.filter((i) => i.price);
    setTotalOrder(order);
    return order;
  };

  const getSum = (values) => {
    let structuredOrder = { ...values };
    for (var key in structuredOrder) {
      if (structuredOrder.hasOwnProperty(key)) {
        structuredOrder[key] = {
          count: +structuredOrder[key],
          price: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.price)[0],
          name: [...fakeTickets]
            .filter((item) => {
              return item.ticket == key;
            })
            .map((item) => item.name)[0],
        };
      }
    }

    const tempArrayOrder = [];
    for (var o in structuredOrder) {
      tempArrayOrder.push(structuredOrder[o]);
    }
    const order = tempArrayOrder.filter((i) => i.price);
    const res = order
      .map((item) => {
        return item.count * item.price;
      })
      .reduce((a, b) => a + b, 0);
    return res;
  };

  useEffect(() => {
    setStep(1);
    setShow(false);
  }, []);

  return (
    // <ModalShowContext.Provider value={{ show: show, setShow }}>
    // <ModalStepContext.Provider value={{ currentStep: step, setStep }}>
    <div className={`${DetailCss.eventDetailPage}`}>
      <div className={` ${modalShowHide} ${DetailCss.overlayWrapper}`}>
        <div className={`${DetailCss.overlay}`}></div>
        <a
          onClick={() => showModal()}
          className={`${DetailCss.closeButton}`}
          role="button"
        >
          <svg
            className={`${DetailCss.closeIcon}`}
            xmlns="http://www.w3.org/2000/svg"
            width="57.276"
            height="57.276"
            viewBox="0 0 57.276 57.276"
          >
            <g
              id="Group_1"
              data-name="Group 1"
              transform="translate(-593.801 -249.423)"
            >
              <line
                id="Line_1"
                data-name="Line 1"
                y2="70"
                transform="translate(647.188 253.312) rotate(45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
              <line
                id="Line_2"
                data-name="Line 2"
                y2="70"
                transform="translate(597.69 253.312) rotate(-45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
            </g>
          </svg>
        </a>
        <div className={`${DetailCss.overlayWindow}`}>
          <div className={`${DetailCss.setBg}`}>
            {currentStep === 1 && <TicketModal />}
            {currentStep === 2 && <OrderModal />}
            {currentStep === 3 && <PaymentModal />}
            {currentStep === 4 && <PrivacyPolicyModal />}
            {currentStep === 5 && <ContactModal />}
            {currentStep === 6 && <PreContactModal />}
            {currentStep === 7 && <ResendTicketModal />}
            {currentStep === 8 && <EmailModal />}
            {currentStep === 9 && <TermsOfServicesModal />}
          </div>
        </div>
      </div>
      <div className={`${DetailCss.bgBlurWrap}`}>
        <div className={`${DetailCss.bGBlur}  ${DetailCss.blurImg1}`}></div>
      </div>

      <div className={`${DetailCss.detailPage}`}>
        <header className={`${DetailCss.header}`}>
          <div className={`${DetailCss.headerTab}`}>
            <a className={`${DetailCss.headerText}`} href="">
              <span className={`${DetailCss.headerText2}`}>Nowruz Party</span>
            </a>
          </div>
        </header>

        <div className={`${DetailCss.eventListPage}`}>
          <header
            className={`${DetailCss.eventListHeader} ${DetailCss.blurImg1}`}
          >
            <div>
              <img className={`${DetailCss.eventImg}`} src={eventImg} alt="" />
            </div>
          </header>
          <div className={`${DetailCss.eventTitleBox}`}>
            <h2 className={`${DetailCss.eventTitleH2}`}>Nowruz Party</h2>
            <div className={`${DetailCss.timeLoc}`}>
              <h2 className={`${DetailCss.eventTimeLoc}`}>
                <i className={`fa fa-calendar ${DetailCss.Icon}`}></i>
                Fri
                <var className={`mx-1 ${DetailCss.var}`}>18</var>
                March
                <var className={`mx-1 ${DetailCss.var}`}>2022</var>, 22:00 -
                5:00
              </h2>
              <h2 className={`${DetailCss.eventTimeLoc}`}>
                <i className={`fa fa-map-marker ${DetailCss.Icon}`}></i>
                One Club, Melantrichova 5, Prague 1
              </h2>
            </div>
            <div className={`${DetailCss.headingBorderLine}`}></div>
          </div>

          <div className={`${DetailCss.contentWraper}`}>
            <h2 className={`${DetailCss.selectTickets}`}>SELECT TICKETS</h2>

            <Formik
              initialValues={{
                ticketNum: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                getOrder(values);

                {
                  Object.keys(values).length > 1 && showModal();
                }
                {
                  Object.keys(values).length > 1
                    ? setErrorShow(false)
                    : setErrorShow(true);
                }
              }}
            >
              {({ values }) => (
                <div>
                  {/* alert if no ticket selected */}
                  <div
                    className={`error ${showHide} ${DetailCss.errorAlertBox}`}
                  >
                    <p className={`${DetailCss.alertMsg}`}>
                      <strong>Please note the following errors:</strong>
                      <br />
                      You need to select at least one ticket to make an order.
                    </p>
                  </div>
                  {/* end of alert if no ticket selected */}
                  <Form>
                    <div className={`${DetailCss.formItems}`}>
                      {fakeTickets.map((ticket) => (
                        <EventTicketRow
                          name={ticket.name}
                          price={ticket.price}
                          ticketNum={ticket.ticket}
                        />
                      ))}

                      <button
                        type="submit"
                        className={`${DetailCss.ticketButton}`}
                      >
                        Buy tickets
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>

          <div className={`${DetailCss.contentWraper}`}>
            <div className={`${DetailCss.leftCol}`}>
              <h3 className={`${DetailCss.description}`}>Description</h3>
              <div className={`${DetailCss.eventDescription}`}>
                <p className={`${DetailCss.text}`}>
                  <span className={`${DetailCss.title}`}>
                    The best Nowruz party in Czech Republic and Prague
                  </span>
                </p>
                <p className={`${DetailCss.text}`}>
                  In a modern and classic club in the heart of Prague ❗️
                </p>
                <p className={`${DetailCss.text}`}>
                  -With two different music stages (Persian and international){" "}
                  <br />
                  -confetti show 🎊 <br />
                  -best dancers in Prague 💃 <br />
                  -best Djs in town 🎧 <br />
                  -special offers for drinks 🍹
                </p>
                <p className={`${DetailCss.text}`}>
                  Hope to see you and celebrate Nowruz together!
                </p>
                <p className={`${DetailCss.text}`}>
                  📍One Club, Melantrichova 5, Prague 1
                </p>
              </div>
              <h3 className={`${DetailCss.description}`}>Location</h3>

              <iframe
                className={`${DetailCss.mapImg}`}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20482.304885975715!2d14.424686675811241!3d50.08089245401663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94eea48a224d%3A0x78c5293fe69062aa!2sOne%20Club!5e0!3m2!1sen!2s!4v1644408678018!5m2!1sen!2s"
              ></iframe>

              <button
                onClick={() => handleResendTicketModalShow()}
                className={`${DetailCss.endBtn}`}
              >
                Resend my ticket
              </button>
              <br />
              <button
                onClick={() => handleContactModalShow()}
                className={`${DetailCss.endBtn}`}
              >
                Contact the event organiser
              </button>
            </div>
            <div className={`${DetailCss.rightCol}`}>
              <div className={`${DetailCss.tickets}`}>
                <div className={`${DetailCss.ticketWrapper}`}></div>
              </div>
              <div className={`${DetailCss.share}`}>
                <h3 className={`${DetailCss.description}`}>Share this event</h3>
                <ul className={`d-flex ${DetailCss.shareOptions}`}>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-facebook ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-twitter ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-linkedin ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <br className={`${DetailCss.both}`} />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
    // </ModalStepContext.Provider>
    // </ModalShowContext.Provider>
  );
};

export default DiaccoEventDetail;
