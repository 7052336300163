import React from "react";
import { Field, ErrorMessage } from "formik";
import TicketRowCss from "./TicketRow.module.css";

const TicketRow = ({ name, price, ticketNum }) => {
  return (
    <div className={`${TicketRowCss.ticketRow}`}>
      <span className={`${TicketRowCss.ticketName}`}>
        <label htmlFor="">
          <var className={`${TicketRowCss.ticketVar}`}>{name}</var>
          <div className={`${TicketRowCss.ticketPrice}`}>
            {price},- CZK
            <span className={`${TicketRowCss.ticketPrice2}`}> </span>
          </div>
        </label>
      </span>
      <span className={`${TicketRowCss.ticketQuantity}`}>
        <Field
          as="select"
          name={ticketNum}
          className={`${TicketRowCss.ticketSelect}`}
        >
          <option value="0" selected="selected">
            0
          </option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Field>
      </span>
      <br className={`${TicketRowCss.space}`} />
    </div>
  );
};

export default TicketRow;
