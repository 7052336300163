import React from "react";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import OrderCss from "../OrderModal/OrderModal.module.css";

const EmailModal = () => {
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody}`}>
            <header className={`${ModalCss.modalHeader}`}>
              <h2 className={`${ModalCss.headerTitle}`}>
                Resend your ticket for Fri 18 March 2022 10:00 PM - 5:00 AM:
                Nowruz Party @One Club, Melantrichova 5, Prague 1
              </h2>
            </header>

            <div className={`${OrderCss.formHolder}`}>
              <div className={`${OrderCss.checkoutForm}`}>
                <form action="">
                  <dl className={`${OrderCss.zendForm} mt-4`}>
                    <Input
                      id="email"
                      type="email"
                      name="Please enter the email address used to make the order"
                      value=""
                      element="email-element"
                      required="true"
                    />

                    <div className={`${OrderCss.inputHolder}`}>
                      <dd
                        id="submit-element"
                        className={`${OrderCss.submitElement}`}
                      >
                        <input
                          className={`${OrderCss.submitItem}`}
                          type="submit"
                          id="submit"
                          name="submit"
                          value="Resend tickets"
                          required={true}
                        />
                      </dd>
                    </div>
                  </dl>
                </form>
                <br className={`${OrderCss.spr}`} />
              </div>
            </div>
          </div>
        </div>

        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default EmailModal;
