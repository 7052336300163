import React, { useContext } from 'react';
import ModalStepContext from '../../core/context/ModalStepContext';
import ModalShowContext from '../../core/context/ModalShowContext';
import FooterCss from './Footer.module.css';

const Footer = () => {
    const p = useContext(ModalStepContext);
    const s = useContext(ModalShowContext);
    
    const handlePrivacyShow = () => {
        s.setShow(!s.show);
        p.setStep(4);
        
    }

    const handlePreContactModalShow = () => {
        s.setShow(!s.show);
        p.setStep(6);
    }
    return (
        <footer className={`${FooterCss.footerBg}`}>
            <div className={` ${FooterCss.footerContainer}`}>
                <p className={`${FooterCss.needHelp}`}>
                    <i className={`fa fa-question-circle-o ${FooterCss.questionIcon} `}></i>
                    Need help or lost your ticket?
                    <span className={`${FooterCss.helpLinks}`}>
                        <a onClick={() => handlePreContactModalShow()} className="ms-2 text-decoration-underline">Contact the event organiser</a>
                        <a onClick={() => handlePrivacyShow()} className="text-decoration-underline">Privacy Policy</a>
                    </span>
                </p>
            </div>

        </footer>
    );
}

export default Footer;