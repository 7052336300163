import React from "react";
import OrderCss from "./OrderModal.module.css";

const OrderTicketRow = ({ name, price, quantity }) => {
  const getSum = (quantity, price) => quantity * price;
  return (
    <tr className={`${OrderCss.ticketRow}`}>
      <th className={`${OrderCss.ticketRowName}`}>{name}</th>
      <th className={`text-end ${OrderCss.ticketRowPrice}`}>{price},- CZK</th>
      <th className={`ps-0 ${OrderCss.ticketRowQuantity}`}> × {quantity} </th>
      <th className={`text-end ${OrderCss.ticketRowSutotal}`}>
        {getSum(quantity, price)},- CZK
      </th>
    </tr>
  );
};

export default OrderTicketRow;
