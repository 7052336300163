import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import InvestImg from "../../../assets/img/Diacco_Invest.svg";
import InvestHeaderCss from "./DiaccoInvestHeader.module.css";

const DiaccoInvestHeader = () => {
  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleContactUs = () => {
    history.push("/contact_us");
  };
  const handleInvest = () => {
    history.push("/invest");
  };
  const handleLanding = () => {
    history.push("/");
  };

  return (
    <Nav className={`d-flex justify-content-between ${InvestHeaderCss.navBg}`}>
      <NavItem>
        <NavLink>
          <img src={InvestImg} className={`${InvestHeaderCss.logo}`} alt="" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#">
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className={`${InvestHeaderCss.button}`}
          >
            <DropdownToggle className={`${InvestHeaderCss.button}`}>
              <svg
                className={`${InvestHeaderCss.menuIcon}`}
                xmlns="http://www.w3.org/2000/svg"
                width="128.923"
                height="75.935"
                viewBox="0 0 128.923 75.935"
              >
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(-475 -407)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M0,0H128.923V75.935H0Z"
                    transform="translate(475 407)"
                    // fill="#3d424a"
                    fill-opacity="0"
                  />
                  <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="95.049"
                    transform="translate(490.921 430.877)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                  />
                  <line
                    id="Line_2"
                    data-name="Line 2"
                    x2="95.049"
                    transform="translate(490.921 459.059)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </DropdownToggle>
            <DropdownMenu className={`${InvestHeaderCss.menu}`}>
              <DropdownItem
                className={`pt-1 pb-0 mb-2 ${InvestHeaderCss.navItem} ${InvestHeaderCss.navItemstart}`}
                href="/"
                tag="a"
                style={{ color: "#cf9a63" }}
                onClick={handleLanding}
              >
                DIACCO GROUP
              </DropdownItem>
              <DropdownItem
                href="/"
                tag="a"
                className={`py-0 mb-2 ${InvestHeaderCss.navItem}`}
              >
                <Link to="/invest">
                  <span className={`ps-3 pe-2 ${InvestHeaderCss.navSpan}`}>
                    |
                  </span>{" "}
                  DIACCO INVEST
                </Link>
              </DropdownItem>
              <DropdownItem
                href="/"
                tag="a"
                className={`py-0 mb-2 ${InvestHeaderCss.navItem}`}
              >
                <Link
                //  to="/fleet"
                >
                  <span className={`ps-3 pe-2 ${InvestHeaderCss.navSpan}`}>
                    |
                  </span>{" "}
                  DIACCO FLEET
                </Link>
              </DropdownItem>
              <DropdownItem
                href="/"
                tag="a"
                className={`py-0 mb-1 ${InvestHeaderCss.navItem}`}
              >
                <Link to="/events">
                  <span className={`ps-3 pe-2 ${InvestHeaderCss.navSpan}`}>
                    |
                  </span>{" "}
                  DIACCO EVENT
                </Link>
              </DropdownItem>
              <DropdownItem
                className={`pb-1 pt-1 ${InvestHeaderCss.navItem} ${InvestHeaderCss.navItemend}`}
                href="/"
                tag="a"
                style={{ color: "#cf9a63" }}
                onClick={handleContactUs}
              >
                CONTACT US
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default DiaccoInvestHeader;
