import React, {useContext} from 'react';
import ModalStepContext from '../../../../core/context/ModalStepContext';
import ModalCss from '../Modal.module.css';
import preContactCss from './PreContactModal.module.css';
import ModalFooter from '../ModalFooter';

const PreContactModal = () => {

    const { currentStep, setStep } = useContext(ModalStepContext);
    console.log(currentStep,"currentStep");

      const handleResendTicketModalShow = () => {
         setStep(7);
     }
     const handleContactModalShow = () => {
        setStep(5);
    }

    return (
        <div className={`${ModalCss.windowHolder}`}>
            <div className={`${ModalCss.shopContainer}`}>
                <div className={`${ModalCss.modalBodyWraper}`}>
                    <div className={`${ModalCss.modalBody}`}>
                        <header className={`${ModalCss.modalHeader}`}>
                            <h2 className={`${ModalCss.headerTitle}`}>
                                Contact  Nowruz Party
                            </h2>
                        </header>
                        <br />
                        <p>Please select from the options below.</p>
                        <ul className={preContactCss.helpLinks}>
                            <li>
                                <a onClick={() => handleResendTicketModalShow()} className={`text-decoration-underline ${preContactCss.cursor}`}>Please resend my ticket / order confirmation</a>
                            </li>
                            <li>
                                <a onClick={() => handleContactModalShow()} className={`text-decoration-underline ${preContactCss.cursor}`}>I have a question related to an event or order</a>
                            </li>
                        </ul>


                    </div>
                </div>
                <ModalFooter leftItems={false} />

            </div>
        </div>
    );
}

export default PreContactModal;