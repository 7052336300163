import React from "react";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import OrderCss from "../OrderModal/OrderModal.module.css";
import contactCss from "./ContactModal.module.css";

const ContactModal = () => {
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody}`}>
            <header className={`${ModalCss.modalHeader}`}>
              <h2 className={`${ModalCss.headerTitle}`}>
                Contact Nowruz Party
              </h2>
            </header>
            <div className={`${OrderCss.formHolder2}`}>
              <p className="mt-3">Please complete the form below</p>
              <div className={`${OrderCss.checkoutForm}`}>
                <form action="">
                  <dl className={`${OrderCss.zendForm}`}>
                    <Input
                      id="name"
                      type="text"
                      name="Your name"
                      element="name-element"
                      errorMsg=""
                      required={true}
                    />
                    <Input
                      id="email"
                      type="email"
                      name="Your email"
                      element="email-element"
                      required={true}
                    />
                    <TextereaInput
                      id="message"
                      name="Message"
                      element="message-element"
                      required={false}
                    />
                    <SelectInput
                      id="event"
                      name="Please select the event you purchased for"
                      element="event-element"
                      required={false}
                      value={[
                        "Please select",
                        "Fri 18 March 2022 10:00 PM - 5:00 AM: Nowruz Party",
                      ]}
                    />

                    <div className={`${OrderCss.inputHolder}`}>
                      <dd
                        id="submit-element"
                        className={`${OrderCss.submitElement}`}
                      >
                        <input
                          className={`${OrderCss.submitItem}`}
                          type="submit"
                          id="submit"
                          name="submit"
                          value="Send message"
                        />
                      </dd>
                    </div>
                  </dl>
                </form>
                <br className={`${OrderCss.spr}`} />
              </div>
            </div>
          </div>
        </div>

        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default ContactModal;
