import React from "react";
import Footer from "../Footer/Footer";
import eventImg from "../../assets/img/ev-d3.jpg";
import locImg from "../../assets/img/map2.jpg";
import DetailCss from "./DiaccoEventDetail.module.css";

const DiaccoEventDetail3 = () => {
  return (
    <div className={`${DetailCss.eventDetailPage}`}>
      <div className={`${DetailCss.bgBlurWrap}`}>
        <div className={`${DetailCss.bGBlur} ${DetailCss.blurImg3}`}></div>
      </div>

      <div className={`${DetailCss.detailPage}`}>
        <header className={`${DetailCss.header}`}>
          <div className={`${DetailCss.headerTab}`}>
            <a className={`${DetailCss.headerText}`} href="">
              <span className={`${DetailCss.headerText2}`}>
                Erasmus Welcome II.
              </span>
            </a>
          </div>
        </header>

        <div className={`${DetailCss.eventListPage}`}>
          <header
            className={`${DetailCss.eventListHeader} ${DetailCss.blurImg3}`}
          >
            <div>
              <img className={`${DetailCss.eventImg}`} src={eventImg} alt="" />
            </div>
          </header>
          <div className={`${DetailCss.eventTitleBox}`}>
            <h2 className={`${DetailCss.eventTitleH2}`}>Erasmus Welcome II.</h2>
            <div className={`${DetailCss.timeLoc}`}>
              <h2 className={`${DetailCss.eventTimeLoc}`}>
                <i className={`fa fa-calendar ${DetailCss.Icon}`}></i>
                Mon
                <var className={`mx-1 ${DetailCss.var}`}>20</var>
                April
                <var className={`mx-1 ${DetailCss.var}`}>2022</var>
              </h2>
              <h2 className={`${DetailCss.eventTimeLoc}`}>
                <i className={`fa fa-map-marker ${DetailCss.Icon}`}></i>
                Maj 4 Patro
              </h2>
            </div>
            <div className={`${DetailCss.headingBorderLine}`}></div>
          </div>
          <div className={`${DetailCss.contentWraper}`}>
            <div className={`${DetailCss.leftCol}`}>
              <h3 className={`${DetailCss.description}`}>Description</h3>
              <div className={`${DetailCss.eventDescription}`}>
                <p className={`${DetailCss.text}`}>
                  <span className={`${DetailCss.title}`}>
                    💥 Grand Erasmus Ceremony 💥
                  </span>
                </p>
                <p className={`${DetailCss.text}`}>
                  In a modern and classic club in the heart of Prague ❗️
                </p>
                <p className={`${DetailCss.text}`}>
                  The biggest Erasmus Kickoff is near. Time to welcome the new
                  students from all around the world and show the a great time
                  with the Number On Entertainment of Prague
                </p>
                <p className={`${DetailCss.text}`}>
                  We here to give you one more unforgettable night in one of the
                  biggest venues in Prague, Maj 4 Patro, biggest rooftop with
                  the greatest view of the city. 🔥
                </p>
                <p className={`${DetailCss.text}`}>
                  This is your chance to party with Warehouse Entertainment and
                  Erasmus in Prague, get crazy and create memories from a
                  special night.
                </p>
                <p className={`${DetailCss.text}`}>
                  Be ready for: <br />
                  *Confetti show 🎊
                  <br />
                  *Best DJ’s 🌍 <br />
                  *Professional dancers 👯 <br />
                  *Cheap prices
                </p>
                <p className={`${DetailCss.text}`}>
                  Enjoy an unforgettable night in the best way ever with the
                  Number One Entertainment of Prague and Erasmus In Prague🇨🇿
                </p>
                <p className={`${DetailCss.text}`}>📍 Maj 4 Patro</p>
                <p className={`${DetailCss.text}`}>📅 Wedensday 15.4.2020</p>
                <p className={`${DetailCss.text}`}>⏰ 10pm</p>
              </div>
              <h3 className={`${DetailCss.description}`}>Location</h3>
              <p className={`${DetailCss.text}`}>Maj 4 Patro, 110 00</p>
              {/* <div className={`${DetailCss.locImg}`}>
                                <img src={locImg} alt="" />
                            </div> */}
              <iframe
                className={`${DetailCss.mapImg}`}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20482.304885975715!2d14.424686675811241!3d50.08089245401663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94eea48a224d%3A0x78c5293fe69062aa!2sOne%20Club!5e0!3m2!1sen!2s!4v1644408678018!5m2!1sen!2s"
              ></iframe>
              <h3 className={`${DetailCss.description}`}>
                Need help or lost your ticket?
              </h3>
              <button className={`${DetailCss.endBtn}`}>
                Contact the event organiser
              </button>
            </div>
            <div className={`${DetailCss.rightCol}`}>
              <div className={`${DetailCss.tickets}`}>
                <div className={`${DetailCss.ticketWrapper}`}>
                  <button className={`${DetailCss.ticketButton}`}>
                    Sold out
                  </button>
                </div>
              </div>
              <div className={`${DetailCss.share}`}>
                <h3 className={`${DetailCss.description}`}>Share this event</h3>
                <ul className={`d-flex ${DetailCss.shareOptions}`}>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-facebook ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-twitter ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                  <li className={`${DetailCss.iconBox}`}>
                    <a className={`${DetailCss.icon}`} href="">
                      <i className={`fa fa-linkedin ${DetailCss.ic}`}></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <br className={`${DetailCss.both}`} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DiaccoEventDetail3;
